<template>
  <div class="box-cta p-6">
    <h3 class="has-text-centered">Join our community!</h3>
    <div
      class="
        is-flex-is-align-items-center is-justify-content-space-between
        my-5
      "
    >
      <a href="https://twitter.com/Zappy_Official" target="_blank"
        ><img class="social-icon mr-4" src="../assets/img/tw.png" alt=""
      /></a>
      <a href="https://www.instagram.com/joinzappy/" target="_blank"
        ><img class="social-icon mr-4" src="../assets/img/ig.png" alt=""
      /></a>
      <a href="https://t.me/ZappyInternational" target="_blank"
        ><img class="social-icon mr-4" src="../assets/img/tg.png" alt=""
      /></a>
      <a href="https://www.facebook.com/zappyofficialpage" target="_blank"
        ><img class="social-icon" src="../assets/img/fb.png" alt=""
      /></a>
    </div>
    <h4 class="mb-5">Subscribe to our newsletter</h4>
    <div class="" :style="[!isXS ? { width: '50%' } : { width: '90%' }]">
      <div id="mc_embed_signup">
        <form
          action="https://joinzappy.us1.list-manage.com/subscribe/post?u=aa87d227f17bb616278778862&amp;id=1790dc763f&amp;f_id=00d990e5f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
              <input
                type="email"
                value=""
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                placeholder="insert your email..."
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style="display: none"
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style="display: none"
            ></div>
          </div>
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_0910fce3a51510bfc3bcc8ddb_4bd091b827"
              tabindex="-1"
              value=""
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "../mixins/checkViewport";

export default {
  name: "call-to-action",
  mixins: [checkViewport],
  data() {
    return {
      name: "",
    };
  },
};
</script>
