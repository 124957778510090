<template>
  <div>
    <div v-if="!web3Store.isLoadingDate" class="box-card">
      <div class="box-card-content">
        <h3>{{ daysW }}</h3>
        <p class="is-small"><b>days</b></p>
      </div>
      <div class="v-divider"></div>
      <div class="box-card-content">
        <h3>{{ hoursW }}</h3>
        <p class="is-small"><b>hours</b></p>
      </div>
      <div class="v-divider"></div>
      <div class="box-card-content">
        <h3>{{ minutesW }}</h3>
        <p class="is-small"><b>minutes</b></p>
      </div>
      <div class="v-divider"></div>
      <div class="box-card-content">
        <h3>{{ secondsW }}</h3>
        <p class="is-small"><b>seconds</b></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3Store";
import checkViewport from "../mixins/checkViewport";

export default {
  name: "countdown",
  mixins: [checkViewport],
  data() {
    return {
      daysW: 0,
      hoursW: 0,
      minutesW: 0,
      secondsW: 0,
    };
  },
  computed: {
    ...mapStores(useWeb3Store),
  },
  mounted() {
    const app = this;
    const unsubscribe = app.web3Store.$onAction(({ name, store, after }) => {
      after(async () => {
        if (name === "fetchSaleInfo") {
          if (store.web3) {
            app.secondsToDhmse();
            unsubscribe();
          }
        }
      });
    });
    this.secondsToDhms();
  },
  methods: {
    secondsToDhms() {
      const app = this;
      setInterval(function () {
        let curr = parseInt(new Date().getTime() / 1000);
        let remainingSeconds =
          parseInt(app.web3Store.countdownDate) - parseInt(curr);
        remainingSeconds = Number(remainingSeconds);
        var d = Math.floor(remainingSeconds / (3600 * 24));
        var h = Math.floor((remainingSeconds % (3600 * 24)) / 3600);
        var m = Math.floor((remainingSeconds % 3600) / 60);
        var s = Math.floor(remainingSeconds % 60);

        var dDisplay = d;
        var hDisplay = h;
        var mDisplay = m;
        var sDisplay = s;
        app.daysW = dDisplay;
        app.hoursW = hDisplay;
        app.minutesW = mDisplay;
        app.secondsW = sDisplay;
      }, 1000);
    },
  },
};
</script>
