<template>
  <div
    v-if="
      web3Store.connected &&
      web3Store.account &&
      web3Store.isLeader &&
      $route.params.id !== undefined
    "
  >
    <div class="mb-6">
      <h2>LEADER ACCESS</h2>
      <p class="color-secondary">{{ web3Store.communities.owner }}</p>
    </div>
    <div class="columns is-mobile mt-6">
      <div class="column is-12-mobile is-6-tablet">
        <h3>TOT INVESTIMENTO COMMUNITY</h3>
      </div>
      <div class="column is-12-mobile is-6-tablet">
        <div
          v-if="web3Store.isLoadingState"
          class="loading_box"
          style="height: 100px; width: 100%"
        ></div>
        <div
          v-if="!web3Store.isLoadingState"
          class="box-counter"
          :class="{
            'mx-auto mb-5': isXS || isSM,
          }"
        >
          <div
            v-for="(digitInvested, index) in web3Store.amountInvestedLeader"
            :key="index"
            class="single-digit"
          >
            <span> {{ digitInvested }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile mt-6">
      <div class="column is-12-mobile is-6-tablet">
        <h3>YOUR <br v-if="!isSM || !isXS" />GAIN</h3>
      </div>
      <div class="column is-12-mobile is-6-tablet">
        <div
          v-if="web3Store.isLoadingState"
          class="loading_box"
          style="height: 100px; width: 100%"
        ></div>
        <div
          v-if="!web3Store.isLoadingState"
          class="box-counter"
          :class="{
            'mx-auto': isXS || isSM,
          }"
        >
          <div
            v-for="(digitEarned, index) in web3Store.amountEarnedLeader"
            :key="index"
            class="single-digit"
          >
            <span> {{ digitEarned }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="gap"></div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3Store";
import checkViewport from "../mixins/checkViewport";

export default {
  name: "leader-counter",
  mixins: [checkViewport],
  computed: {
    ...mapStores(useWeb3Store),
  },
};
</script>