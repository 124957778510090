<template>
  <div
    class="
      nav
      is-flex is-align-items-center is-justify-content-space-between
      px-6
    "
  >
    <div>
      <img class="logo-zappy" src="../assets/img/logo.png" alt="" />
    </div>

    <div class="is-flex is-align-items-center">
      <a class="nav-link mr-6" href="https://docsend.com/view/tz2pnctwb45yvf33" target="_blank">Litepaper</a>
      <div
        v-if="web3Store.isLoadingUser"
        class="loading_box"
        style="height: 48px; width: 203px"
      ></div>
      <div
        v-if="!web3Store.isLoadingUser"
        class="is-flex is-align-items-center"
      >
        <span
          :style="[
            web3Store.connected ? { color: '#EAC43C' } : { color: '#fff' },
          ]"
          style="font-size: 3rem"
          class="material-symbols-outlined"
        >
          person
        </span>
        <div v-if="web3Store.account !== undefined && web3Store.connected">
          <p class="nav-link">
            <span v-if="web3Store.account">
              {{
                web3Store.account.substr(0, 6) +
                "..." +
                web3Store.account.substr(-6)
              }}</span
            >
          </p>
          <p v-if="!web3Store.isLeader" class="is-small">User</p>
          <p v-if="web3Store.isLeader" class="is-small">Community Leader</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3Store";
import checkViewport from "../mixins/checkViewport";

export default {
  name: "nav-bar",
  mixins: [checkViewport],
  computed: {
    ...mapStores(useWeb3Store),
  },
};
</script>
