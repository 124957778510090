<template>
  <div :class="{ 'px-6': isMD }">
    <!-- Investements counter -->
    <!-- && web3Store.isLeader -->

    <!-- LEADER -->
    <div v-if="web3Store.connected && web3Store.account">
      <div
        :class="{
          'has-text-centered': isXS || isSM,
          'is-flex is-justify-content-space-between': isMD || isLG || isXL,
        }"
      >
        <div
          v-if="$route.params.id !== undefined"
          class="px-6"
          style="width: 100%"
        >
          <h3>$ INVESTED</h3>
          <div
            v-if="web3Store.isLoadingState"
            class="loading_box"
            style="height: 100px; width: 100%"
          ></div>
          <div
            v-if="!web3Store.isLoadingState"
            class="box-counter"
            :class="{
              'mx-auto mb-5': isXS || isSM,
            }"
          >
            <div
              v-for="(digitInvested, index) in web3Store.amountInvested"
              :key="index"
              class="single-digit"
            >
              <span> {{ digitInvested }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="$route.params.id !== undefined"
          class="px-6"
          style="width: 100%"
        >
          <h3>$ ZPY</h3>
          <div
            v-if="web3Store.isLoadingState"
            class="loading_box"
            style="height: 100px; width: 100%"
          ></div>
          <div
            v-if="!web3Store.isLoadingState"
            class="box-counter"
            :class="{
              'mx-auto': isXS || isSM,
            }"
          >
            <div
              v-for="(digitEarned, index) in web3Store.amountEarned"
              :key="index"
              class="single-digit"
            >
              <span> {{ digitEarned }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="gap"></div>
    </div>

    <!-- Bonus slider -->
    <!-- vi-if="!web3Store.isLeader && web3Store.connected && web3Store.account"  -->
    <div class="has-text-centered">
      <h2 class="mb-6">BONUS</h2>
      <h3 class="">$ZPY Base Price = 0.041</h3>
      <div class="gap"></div>
      <b-field>
        <b-slider
          :min="0"
          :max="22000"
          :step="1"
          v-model="web3Store.bonusAmount"
          disabled
        >
          <b-slider-tick :value="1000"
            ><div>
              <p class="color-secondary is-extralarge">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$250&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p class="is-large">
                $ZPY = <span class="color-secondary">$0.039</span>
              </p>
              <p class="is-large">
                (discount <span class="color-secondary">5%</span> )
              </p>
            </div></b-slider-tick
          >
          <b-slider-tick :value="5000"
            ><div>
              <p class="color-secondary is-extralarge">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$700&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p class="is-large">
                $ZPY = <span class="color-secondary">$0.037</span>
              </p>
              <p class="is-large">
                (discount <span class="color-secondary">9%</span> )
              </p>
            </div></b-slider-tick
          >
          <b-slider-tick :value="10000"
            ><div>
              <p class="color-secondary is-extralarge">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p class="is-large">
                $ZPY = <span class="color-secondary">$0.034</span>
              </p>
              <p class="is-large">
                (discount <span class="color-secondary">17%</span> )

                <br />+<br /><span class="color-secondary">T-SHIRT & NFT </span
                >LIMITED EDITION <br />+<br /><span class="color-secondary">
                  1 MOUNTH</span
                >
                DISCOUNT ON VESTING PERIOD
              </p>
            </div></b-slider-tick
          >
          <b-slider-tick :value="15000"
            ><div>
              <p class="color-secondary is-extralarge">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$5.000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p class="is-large">
                $ZPY = <span class="color-secondary">$0.032</span><br />(sconto
                <span class="color-secondary">22%</span>) <br />+<br />BENEFITS
                OF THE<br />
                <span class="color-secondary">1000 </span><br />+<br /><span
                  class="color-secondary"
                >
                  VIP PASS</span
                >
              </p>
            </div></b-slider-tick
          >
          <b-slider-tick :value="20000"
            ><div>
              <p class="color-secondary is-extralarge">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$10.000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p class="is-large">
                $ZPY = <span class="color-secondary">$0.030</span
                ><br />(discount
                <span class="color-secondary">30%</span>
                )<br />+<br />BENEFITS OF THE
                <span class="color-secondary">5000</span>
                <br />+<br /><span class="color-secondary">ZAPPY WATCH </span>
                LIMITED EDITION<br />
              </p></div
          ></b-slider-tick>
          <b-slider-tick :value="22000"></b-slider-tick>
        </b-slider>
      </b-field>
    </div>

    <!-- Benefits -->
    <div v-if="!isXS && !isSM" class="gap"></div>
    <div v-if="!isXS && !isSM" class="gap"></div>
    <div class="gap"></div>
    <div class="gap"></div>
    <div class="gap mb-6"></div>
    <div class="columns is-mobile is-multiline">
      <div class="column is-12-mobile is-4-tablet is-4-desktop">
        <div class="box-bonus">
          <div
            class="box-top-image"
            :style="[
              isTshirt
                ? {
                    'background-image': 'url(/assets/img/tshirt.png)',
                    transition: 'background-image 0.5s ease-in-out',
                  }
                : {
                    'background-image': 'url(/assets/img/tshirt_2.png)',
                    transition: 'background-image 0.5s ease-in-out',
                  },
            ]"
          ></div>
          <div class="has-text-centered">
            <h4 class="mb-5">T-SHIRT <br />LIMITED EDITION</h4>
            <p class="is-medium is-transparent" style="text-align: justify">
              Zappy's first limited-edition T-shirt, both physical and as an NFT
              accessory for your avatar! (available from Beta)
            </p>
          </div>
        </div>
        <div v-if="isXS || isSM" class="gap"></div>
      </div>

      <div class="column is-12-mobile is-4-tablet is-4-desktop">
        <div class="box-bonus">
          <div class="box-top-image">
            <video
              preload="auto"
              loop
              autoplay
              muted
              defaultMuted
              playsinline
              style="border-radius: 100px"
            >
              <source src="../assets/video/pass.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="">
            <h4 class="mb-5 has-text-centered">VIP PASS</h4>
            <p class="is-medium is-transparent" style="text-align: justify">
              Produced in only 1000 pieces, this NFT will give access to:
            </p>
            <ul class="pl-3 mt-2" style="list-style: inside">
              <li class="is-transparent">
                2 whitelists for the Zappy genesis NFT collection;
              </li>
              <li class="is-transparent">
                1 free mint for the Zappy genesis NFT collection;
              </li>
              <li class="is-transparent">
                Zappy Beta access to try the app before others and unlock rare
                NFTs;
              </li>
              <li>VIP access to all official Zappy events.</li>
            </ul>
          </div>
        </div>
        <div v-if="isXS || isSM" class="gap"></div>
      </div>

      <div class="column is-12-mobile is-4-tablet is-4-desktop">
        <div class="box-bonus">
          <div
            class="box-top-image"
            :style="{
              'background-image': 'url(/assets/img/watch.png)',
            }"
          ></div>
          <div class="has-text-centered">
            <h4 class="mb-5">ZAPPY WATCH <br />LIMITED EDITION</h4>
            <p class="is-medium is-transparent" style="text-align: justify">
              It's Zappy time! This exclusive watch comes with a leather strap
              and was produced in only 20 pieces.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3Store";
import checkViewport from "../mixins/checkViewport";

export default {
  name: "bonus-info",
  mixins: [checkViewport],
  data() {
    return {
      isTshirt: false,
    };
  },
  computed: {
    ...mapStores(useWeb3Store),
  },
  mounted() {
    this.changeBg();
  },
  methods: {
    changeBg() {
      const app = this;
      setInterval(function () {
        app.isTshirt = !app.isTshirt;
      }, 2000);
    },
  },
};
</script>
