var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.web3Store.connected &&
    _vm.web3Store.account &&
    _vm.web3Store.isLeader &&
    _vm.$route.params.id !== undefined
  )?_c('div',[_c('div',{staticClass:"mb-6"},[_c('h2',[_vm._v("LEADER ACCESS")]),_c('p',{staticClass:"color-secondary"},[_vm._v(_vm._s(_vm.web3Store.communities.owner))])]),_c('div',{staticClass:"columns is-mobile mt-6"},[_vm._m(0),_c('div',{staticClass:"column is-12-mobile is-6-tablet"},[(_vm.web3Store.isLoadingState)?_c('div',{staticClass:"loading_box",staticStyle:{"height":"100px","width":"100%"}}):_vm._e(),(!_vm.web3Store.isLoadingState)?_c('div',{staticClass:"box-counter",class:{
          'mx-auto mb-5': _vm.isXS || _vm.isSM,
        }},_vm._l((_vm.web3Store.amountInvestedLeader),function(digitInvested,index){return _c('div',{key:index,staticClass:"single-digit"},[_c('span',[_vm._v(" "+_vm._s(digitInvested))])])}),0):_vm._e()])]),_c('div',{staticClass:"columns is-mobile mt-6"},[_c('div',{staticClass:"column is-12-mobile is-6-tablet"},[_c('h3',[_vm._v("YOUR "),(!_vm.isSM || !_vm.isXS)?_c('br'):_vm._e(),_vm._v("GAIN")])]),_c('div',{staticClass:"column is-12-mobile is-6-tablet"},[(_vm.web3Store.isLoadingState)?_c('div',{staticClass:"loading_box",staticStyle:{"height":"100px","width":"100%"}}):_vm._e(),(!_vm.web3Store.isLoadingState)?_c('div',{staticClass:"box-counter",class:{
          'mx-auto': _vm.isXS || _vm.isSM,
        }},_vm._l((_vm.web3Store.amountEarnedLeader),function(digitEarned,index){return _c('div',{key:index,staticClass:"single-digit"},[_c('span',[_vm._v(" "+_vm._s(digitEarned))])])}),0):_vm._e()])]),_c('div',{staticClass:"gap"})]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12-mobile is-6-tablet"},[_c('h3',[_vm._v("TOT INVESTIMENTO COMMUNITY")])])}]

export { render, staticRenderFns }