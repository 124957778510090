<template>
  <div class="bg-gradient" :class="{ 'px-5': isSM || isXS }">
    <Navbar />
    <div class="gap"></div>
    <div class="container py-6">
      <div class="columns is-mobile is-centered">
        <div class="column is-12-mobile is-12-tablet is-10-desktop">
          <h1 class="has-text-centered">Welcome to Zappy's Private Sale</h1>
          <!-- main section -->
          <div class="columns is-mobile is-centered my-6">
            <div class="column is-12-mobile is-8-tablet is-8-desktop">
              <div class="has-text-centered">
                <!-- <p>in collaborazione con</p>
                <img
                  width="50%"
                  class="mb-6 mt-3"
                  src="../assets/img/logoslogan.png"
                  alt=""
                /> -->
                <p>
                  Zappy is the world's first data monetization app, where users
                  can earn money by watching videos of their favorite brands,
                  sharing their preferences with them, creating and selling NFT
                  accessories for their avatars, and playing lots of mini-games.
                </p>
              </div>
            </div>
          </div>

          <div class="gap"></div>

          <!-- second section -->
          <div
            class="columns is-mobile is-multiline is-align-items-center"
            :class="{ 'px-6': isMD }"
          >
            <div class="column is-12-mobile is-6-tablet">
              <h2 class="mb-6">BE ONE OF THE FIRST HOLDERS!</h2>
              <p>
                By participating in this Private Sale, you will be able to
                purchase the Zappy token ($ZPY) at a much lower price than in
                future sale phases. This phase will last for a few days, after
                which you will no longer be able to purchase tokens at the price
                shown in the below section.
              </p>
            </div>
            <div class="column is-12-mobile is-6-tablet">
              <div class="is-flex is-align-items-center">
                <div class="box-img mr-6">
                  <img src="../assets/img/male.png" alt="" />
                </div>
                <div class="box-img">
                  <img src="../assets/img/female.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="gap"></div>

          <!-- Leader Counter -->
          <LeaderCounter />

          <!-- Countdown -->
          <div
            v-if="web3Store.isLoadingDate"
            class="columns is-mobile is-centered"
          >
            <div class="column is-12-mobile is-8-tablet is-8-desktop">
              <div class="has-text-centered">
                <div
                  class="loading_box"
                  style="height: 100px; width: 100%"
                ></div>
              </div>
            </div>
          </div>

          <div
            v-if="web3Store.endDate > 0 && web3Store.startDate > 0"
            class="columns is-mobile is-centered"
          >
            <div class="column is-12-mobile is-8-tablet is-8-desktop">
              <div class="has-text-centered">
                <h2 class="mb-6">
                  Remaining Time
                  <span v-if="web3Store.startDate > new Date().getTime() / 1000"
                    >at the beginning of the sale</span
                  >
                </h2>
                <Countdown />
              </div>
            </div>
          </div>
          <!-- End countdown -->

          <div class="has-text-centered mt-6">
            <div
              v-if="!web3Store.account && !web3Store.connected"
              class="btn-primary"
              @click="web3Store.connect()"
            >
              Join
            </div>
            <div v-if="web3Store.account && web3Store.connected">
              <BuyZappy />
            </div>
          </div>

          <div class="gap"></div>

          <Bonus />

          <div class="gap"></div>
          <div class="gap"></div>
          <div>
            <h3 class="has-text-centered">Why choose $ZPY</h3>
            <div class="gap" :class="{ 'mb-6': isSM || isXS }"></div>
            <Why />
          </div>
          <div v-if="!isXS && !isSM" class="gap"></div>
          <div v-if="!isXS && !isSM" class="gap"></div>
          <div :class="{ 'px-6': isMD }"><Cta /></div>

          <div class="gap"></div>
          <div class="gap"></div>
          <div :class="{ 'px-6': isMD }">
            <p class="is-small" style="text-align: justify">
              Tokens purchased during this Private Sale are subject to 2 months
              of lock and 19 months of linear vesting from TGE. Upon completion
              of each transaction, investors will receive, on the wallet used
              for the purchase, an amount of a ''folded'' version of the $ZPY
              tokens ($FZPY) equal to the number of $ZPY purchased. These tokens
              can be used to claim the $ZPY at the end of the lock period. For
              more information, contact info@joinzappy.io
            </p>
            <p class="has-text-centered is-small mt-4">
              CopyRight - {{ new Date().getFullYear() }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3Store";

import Navbar from "@/components/Navbar.vue";
import Countdown from "@/components/Countdown.vue";
import LeaderCounter from "../components/LeaderCounter.vue";
import Bonus from "@/components/Bonus.vue";
import Why from "@/components/Why.vue";
import Cta from "@/components/Cta.vue";
import BuyZappy from "@/components/BuyZappy.vue";
import checkViewport from "../mixins/checkViewport";

export default {
  name: "home-page",
  mixins: [checkViewport],
  components: {
    Navbar,
    Countdown,
    LeaderCounter,
    Bonus,
    BuyZappy,
    Why,
    Cta,
  },
  computed: {
    ...mapStores(useWeb3Store),
  },
  mounted() {
    const app = this;
    app.web3Store.fetchDateSale();
    if (!app.isSM && !app.isXS) {
      app.checkConnection();
    }

    const unsubscribe = app.web3Store.$onAction(({ name, store, after }) => {
      after(async () => {
        if (name === "connect" || name === "switchNetwork") {
          if (store.web3) {
            app.fetchSaleInfo();
            unsubscribe();
          }
        }
      });
    });
  },
  methods: {
    async checkConnection() {
      const app = this;
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER", "injected")) {
        try {
          await app.web3Store.connect();
          await app.fetchSaleInfo();
        } catch (e) {
          console.log("check error connection", e);
        }
      }
    },
    async fetchSaleInfo() {
      const app = this;
      if (app.web3Store.isDebug) {
        console.log("fetchSaleInfo init");
      }
      app.web3Store.isLoadingState = true;
      // console.log("loading", app.web3Store.isLoadingState);
      if (app.$route.params.id !== undefined) {
        const presaleContract = new app.web3Store.web3.eth.Contract(
          app.web3Store.abiPresale,
          app.web3Store.contractPresale
        );
        // Check Communities
        try {
          // app.web3Store.isLoadingState = true;
          app.web3Store.communities = await presaleContract.methods
            .communities(app.$route.params.id)
            .call();
          if (app.web3Store.isDebug) {
            console.log("communities are:", app.web3Store.communities);
          }
        } catch (e) {
          console.log("I can't fetch communities info", e);
        }

        // Check Communities STATUS
        try {
          // app.web3Store.isLoadingState = true;
          let statusCommunities = await presaleContract.methods
            .isCommunityActive(app.$route.params.id)
            .call();
          if (app.web3Store.isDebug) {
            console.log("The community status is", statusCommunities);
          }
          app.web3Store.communities.isCommunityValid = statusCommunities;
        } catch (e) {
          if (app.web3Store.isDebug) {
            console.log("I can't fetch communities status", e);
          }
          app.web3Store.communities.isCommunityValid = false;
        }

        // Check if account is a community leader
        if (
          app.web3Store.communities.owner === app.web3Store.account ||
          app.web3Store.communities.owner.toLowerCase() ===
            app.web3Store.account.toLowerCase() ||
          app.web3Store.communities.owner.toUpperCase() ===
            app.web3Store.account.toUpperCase()
        ) {
          app.web3Store.isLeader = true;
        } else {
          app.web3Store.isLeader = false;
        }
        if (app.web3Store.isDebug) {
          console.log(
            "Account connected is a community leader?",
            app.web3Store.communities.owner === app.web3Store.account
          );
        }
      } else {
        app.web3Store.communities.isCommunityValid = false;
      }

      // Checking presale balance
      try {
        app.web3Store.isLoadingState = true;
        const wrappedContract = new app.web3Store.web3.eth.Contract(
          app.web3Store.abiWrapped,
          app.web3Store.contractWrapped
        );
        app.web3Store.balancePresale = await wrappedContract.methods
          .balanceOf(app.web3Store.contractPresale)
          .call();
        if (app.web3Store.isDebug) {
          console.log("balance presale", app.web3Store.balancePresale);
        }
      } catch (e) {
        console.log("Can't fetch info from presale", e);
      }

      // Fetch all amount invested/earned
      if (app.$route.params.id !== undefined) {
        const presaleContract = new app.web3Store.web3.eth.Contract(
          app.web3Store.abiPresale,
          app.web3Store.contractPresale
        );
        // TODO: COMMENT LINE BELOW --- This is only for testing Leader Community
        // app.web3Store.isLeader = true;
        if (app.web3Store.isLeader) {
          try {
            app.web3Store.isLoadingState = true;

            // FETCH INVESTED IN $ZPY
            app.web3Store.amountInvestedLeader = await presaleContract.methods
              .totalUsd()
              .call();
            app.web3Store.amountInvestedLeader =
              parseInt(app.web3Store.amountInvestedLeader) / 10 ** 18;
            let percent = app.web3Store.amountInvestedLeader;
            if (app.isDebug) {
              console.log(
                "Total USD Invested",
                app.web3Store.amountInvestedLeader
              );
            }

            // Frontend Counter
            app.web3Store.amountInvestedLeader =
              app.web3Store.amountInvestedLeader.toFixed(0);
            app.web3Store.amountInvestedLeader =
              app.web3Store.amountInvestedLeader.toString().split("");

            // FETCH EARNED BY LEADER
            app.web3Store.isLoadingState = true;

            app.web3Store.amountEarnedLeader = percent;
            app.web3Store.amountEarnedLeader =
              (parseInt(app.web3Store.amountEarnedLeader) * 10) / 100;
            if (app.web3Store.isDebug) {
              console.log(
                "Total USD Earned BY LEADER",
                app.web3Store.amountEarnedLeader
              );
            }
            // Frontend Counter
            app.web3Store.amountEarnedLeader = parseInt(
              app.web3Store.amountEarnedLeader
            ).toFixed(0);

            app.web3Store.amountEarnedLeader = app.web3Store.amountEarnedLeader
              .toString()
              .split("");

            if (app.web3Store.isDebug) {
              console.log(
                "Total $ZPY Bought",
                app.web3Store.amountEarnedLeader
              );
            }
          } catch (e) {
            if (app.web3Store.isDebug) {
              console.log("I can't fetch invested amount", e);
            }
            app.web3Store.isLoadingState = false;
          }
        } else {
          if (app.web3Store.isDebug) {
            console.log(
              "ZPY earned is 0 because you aren't a leader. This account is a leader?",
              app.web3Store.isLeader
            );
          }
        }
        // FETCH AMOUNT OF $ZPY BOUGHT BY USER
        try {
          app.web3Store.isLoadingState = true;
          app.web3Store.amountEarned = await presaleContract.methods
            .zpyBought(app.web3Store.account)
            .call();
          // Frontend Counter
          app.web3Store.amountEarned =
            parseInt(app.web3Store.amountEarned) / 10 ** 9;

          // console.log("THIS IS ZPY BOUGHT", app.web3Store.amountEarned);

          app.web3Store.amountEarned = parseInt(
            app.web3Store.amountEarned
          ).toFixed(0);

          app.web3Store.amountEarned = app.web3Store.amountEarned
            .toString()
            .split("");

          if (app.web3Store.isDebug) {
            console.log("Total $ZPY Bought", app.web3Store.amountEarned);
          }
        } catch (e) {
          console.log("I can't fetch ZPY bought", e);
        }
        // FETCH INVESTED BUSD FOR $ZPY
        try {
          app.web3Store.isLoadingState = true;

          app.web3Store.amountInvested = await presaleContract.methods
            .totalUsdSpentByUser(app.web3Store.account)
            .call();
          app.web3Store.amountInvested =
            parseInt(app.web3Store.amountInvested) / 10 ** 18;
          if (app.isDebug) {
            console.log("Total USD Invested", app.web3Store.amountInvested);
          }

          // Frontend Counter
          app.web3Store.amountInvested =
            app.web3Store.amountInvested.toFixed(0);
          app.web3Store.amountInvested = app.web3Store.amountInvested
            .toString()
            .split("");
        } catch (e) {
          if (app.web3Store.isDebug) {
            console.log("I can't fetch invested amount", e);
          }
          app.web3Store.isLoadingState = false;
        }
      }
      app.web3Store.isLoadingState = false;
      //counter earned
    },
  },
};
</script>
