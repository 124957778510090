import Vue from "vue";
import App from "./App.vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import router from "./router";
import Buefy from "buefy";

import "buefy/dist/buefy.css";

import "./fonts/stylesheet.css";
import "./themes/colors.scss";
import "./themes/style.scss";
import "./themes/responsive.scss";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(Buefy);
Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");
