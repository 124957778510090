<template>
  <div>
    <div v-if="web3Store.isLoadingState"><Spinner /></div>
    <div
      v-if="
        !web3Store.isLoadingState &&
        web3Store.balancePresale !== undefined &&
        parseInt(web3Store.balancePresale) > 0
      "
      class="btn-primary"
      @click="showModal = !showModal"
    >
      BUY
    </div>

    <h3
      v-if="
        !web3Store.isLoadingState &&
        web3Store.balancePresale !== undefined &&
        parseInt(web3Store.balancePresale) === 0
      "
      @click="showModal = !showModal"
    >
      $ZPY unavailable
    </h3>
    <div v-if="showModal" class="modal-overlay">
      <div
        @click="
          showModal = !showModal;
          activeStep = 0;
          isWorking = false;
          workingMessage = '';
          amount = 1000;
          swappedAmount = 0;
          selectedContract = '';
          selectedBalance = '';
        "
        class="top-right"
      >
        <span style="font-size: 3rem" class="material-symbols-outlined">
          close
        </span>
      </div>
      <div class="modal-content" :class="{ 'px-6': !isXS && !isSM }">
        <b-steps
          v-model="activeStep"
          animated
          :rounded="false"
          :has-navigation="false"
        >
          <b-step-item step="1" icon-pack="mdi" icon="check" label="Buy">
            <div class="mt-6">
              <div class="icon-status">
                <img width="130px" src="../assets/img/ico_zappy.png" alt="" />
              </div>
              <div class="mt-5" v-if="isWorking">
                <Spinner />
                <h4
                  v-if="workingMessage !== undefined && workingMessage !== ''"
                  class="mt-4"
                >
                  {{ workingMessage }}
                </h4>
              </div>
              <div
                v-if="!isWorking && web3Store.communities.isCommunityValid"
                class="has-text-centered"
              >
                <h4 class="is-medium-2 is-transparent mt-5 mb-5">
                  Select the amount of $USD you want to invest
                </h4>
                <b-field vertical>
                  <b-input
                    style="margin: auto"
                    :style="[!isXS ? { width: '80%' } : { width: '90%' }]"
                    type="number"
                    :min="5"
                    placeholder="$USD"
                    v-model="amount"
                  ></b-input>
                </b-field>
                <section>
                  <div class="block mt-5">
                    <b-radio
                      class="mr-4"
                      v-model="selectedContract"
                      :name="web3Store.contractBusd"
                      :native-value="web3Store.contractBusd"
                      che
                    >
                      BUSD
                    </b-radio>
                    <b-radio
                      class="mr-4"
                      v-model="selectedContract"
                      :name="web3Store.contractUsdt"
                      :native-value="web3Store.contractUsdt"
                    >
                      USDT
                    </b-radio>
                    <b-radio
                      v-model="selectedContract"
                      :name="web3Store.contractUsdc"
                      :native-value="web3Store.contractUsdc"
                    >
                      USDC
                    </b-radio>
                  </div>
                </section>
                <div
                  class="info"
                  v-if="
                    parseInt(selectedBalance) <= parseInt(web3Store.minToBuy) ||
                    cantBuy
                  "
                >
                  * You do not have sufficient funds<br />
                  your balance is: {{ formattedBalance }}
                </div>
                <button
                  :disabled="
                    selectedContract === '' || parseInt(selectedBalance) === 0
                  "
                  v-if="web3Store.account && web3Store.connected"
                  class="btn-primary mt-6"
                  @click="askSwap()"
                >
                  Buy
                  <div v-if="selectedContract === ''" class="attention">
                    Select a payment method
                  </div>
                </button>
              </div>
              <div class="mt-6" v-if="!web3Store.communities.isCommunityValid">
                <p>
                  Make sure you have received the correct link from the
                  community leader in order to purchase $ZPY.
                </p>
                <div class="btn-primary mt-6" @click="showModal = !showModal">
                  Close
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item step="2" icon-pack="mdi" icon="check" label="Swap">
            <div class="mt-6">
              <div class="icon-status">
                <img width="130px" src="../assets/img/ico_zappy.png" alt="" />
              </div>
              <div class="mt-5" v-if="isWorking">
                <Spinner />
                <h4
                  v-if="workingMessage !== undefined && workingMessage !== ''"
                  class="mt-4"
                >
                  {{ workingMessage }}
                </h4>
              </div>
              <div v-if="!isWorking">
                <h4 class="mt-4">
                  You must approve the swap <br />
                  in order to proceed
                </h4>
                <div
                  v-if="web3Store.account && web3Store.connected"
                  class="btn-primary mt-6"
                  @click="approveSwap()"
                >
                  Confirm
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item step="3" icon-pack="mdi" icon="check" label="Confirm">
            <div class="mt-6">
              <div class="icon-status">
                <img width="130px" src="../assets/img/ico_zappy.png" alt="" />
              </div>
              <h4 class="mt-4">
                Confirm the transaction <br />
                in your wallet
              </h4>
            </div>
          </b-step-item>

          <b-step-item step="4" label="Pending" icon-pack="mdi" icon="check">
            <div class="mt-6">
              <div class="icon-status">
                <img width="130px" src="../assets/img/ico_zappy.png" alt="" />
              </div>
              <h4 class="mt-4 mb-4">
                Transaction pending, <br />
                please wait...
              </h4>
              <a
                class="color-light"
                v-if="pendingTx !== undefined"
                :href="web3Store.explorer + pendingTx"
                style="
                  text-decoration: underline;
                  text-underline-offset: 4px;
                  font-weight: 400;
                "
                target="_blank"
              >
                Transaction pending at:
                {{ pendingTx.substr(0, 4) + "..." + pendingTx.substr(-4) }}</a
              >
            </div>
          </b-step-item>

          <b-step-item step="4" label="Completed" icon-pack="mdi" icon="check">
            <div class="mt-6">
              <div class="icon-status">
                <img width="130px" src="../assets/img/ico_zappy.png" alt="" />
              </div>
              <div>
                <h4 class="mt-4 mb-5">$ZPY purchased correctly.</h4>
                <h4 class="color-light mb-2" v-if="receipt !== undefined">
                  Payment has been completed.
                </h4>

                <a
                  style="
                    text-decoration: underline;
                    text-underline-offset: 4px;
                    font-weight: 400;
                  "
                  v-if="receipt !== undefined"
                  :href="web3Store.explorer + receipt"
                  target="_blank"
                  >Watch on the explorer
                </a>
              </div>
              <div class="btn-primary mt-6" @click="reload()">ok</div>
            </div>
          </b-step-item>
        </b-steps>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3Store";

import checkViewport from "../mixins/checkViewport";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "buy-zappy",
  mixins: [checkViewport],
  data() {
    return {
      activeStep: 0,
      showModal: false,
      isWorking: false,
      workingMessage: "",
      amount: 10,
      swappedAmount: 0,
      pendingTx: "",
      receipt: "",
      selectedContract: "",
      selectedBalance: "",
      formattedBalance: "",
      cantBuy: false,
    };
  },
  components: {
    Spinner,
  },
  computed: {
    ...mapStores(useWeb3Store),
  },
  watch: {
    amount() {
      const app = this;
      if (app.amount < 5) {
        app.amount = 5;
      }
      app.checkBuy();
    },
    selectedContract() {
      const app = this;
      app.selectedBalance = 0;
      // console.log("init change payment methods", app.selectedContract);
      if (app.selectedContract === app.web3Store.contractBusd) {
        app.selectedBalance = app.web3Store.balanceBusd;
      }
      if (app.selectedContract === app.web3Store.contractUsdt) {
        app.selectedBalance = app.web3Store.balanceUsdt;
      }
      if (app.selectedContract === app.web3Store.contractUsdc) {
        app.selectedBalance === app.web3Store.balanceUsdc;
      }
      if (app.web3Store.isDebug) {
        console.log(
          "init change payment methods",
          app.selectedBalance,
          app.selectedContract
        );
      }
      app.checkBuy();
      // console.log("Balance of", app.selectedBalance);
    },
  },
  methods: {
    checkBuy() {
      const app = this;
      let fixedAmount = app.web3Store.web3.utils.toWei(app.amount.toString());
      // console.log(fixedAmount, "fixedAmount");

      if (parseInt(app.selectedBalance) < fixedAmount) {
        app.cantBuy = true;
      } else {
        app.cantBuy = false;
      }
      if (app.web3Store.isDebug) {
        console.log("CANT BUY?", app.cantBuy);
      }

      // FROMAT BALANCE
      app.formattedBalance = app.web3Store.web3.utils.fromWei(
        app.selectedBalance.toString()
      );
      app.formattedBalance = parseInt(app.formattedBalance).toFixed(2);
      // console.log(app.formattedBalance, "formattedBalance");
    },
    async askSwap() {
      const app = this;
      if (app.web3Store.isDebug) {
        console.log("init function to ask SWAP");
      }

      if (app.amount > 0) {
        if (app.web3Store.isDebug) {
          console.log("Amount selected is", app.amount);
        }
        const contract = new app.web3Store.web3.eth.Contract(
          app.web3Store.abiPresale,
          app.web3Store.contractPresale
        );
        try {
          app.isWorking = true;
          let fixedAmount = app.web3Store.web3.utils.toWei(
            app.amount.toString()
          );
          if (app.web3Store.isDebug) {
            console.log("Correct amount:", fixedAmount);
          }
          app.swappedAmount = await contract.methods
            .amountToGetZpy(fixedAmount)
            .call();
          if (app.web3Store.isDebug) {
            console.log("Try amountToGetZpy", app.swappedAmount);
          }

          // Check Allowance and approve
          if (app.web3Store.isDebug) {
            console.log("fixedAmount", fixedAmount);
            console.log("balance", app.selectedBalance);
            console.log(
              "It's Possible call approve function?",
              parseInt(fixedAmount) <= parseInt(app.selectedBalance)
            );
          }

          if (parseInt(fixedAmount) <= parseInt(app.selectedBalance)) {
            const contractAllowance = new app.web3Store.web3.eth.Contract(
              app.web3Store.abiBusd,
              app.selectedContract
            );
            try {
              const allowance = await contractAllowance.methods
                .allowance(app.web3Store.account, app.web3Store.contractPresale)
                .call();
              console.log("Allowance is", allowance);
              if (app.web3Store.isDebug) {
                console.log("allowance is", parseInt(allowance));
                console.log("fixedAmount", parseInt(fixedAmount));
                console.log("balance busd", parseInt(app.selectedBalance));
              }
              //   parseInt(allowance) / 10 ** 18
              if (
                parseInt(fixedAmount) / 10 ** 9 <=
                parseInt(allowance) / 10 ** 9
              ) {
                app.activeStep = 2;
                if (app.web3Store.isDebug) {
                  console.log("now i can buy zpy");
                }
                app.isWorking = false;
                app.buyZpy();
              } else {
                app.activeStep = 1;
                if (app.web3Store.isDebug) {
                  console.log("i need to approve");
                }
                app.workingMessage = "";
                app.isWorking = false;
              }
            } catch (e) {
              alert("Allowance error", e);
              console.log("allowance error", e);
              app.isWorking = false;
            }
          } else {
            app.workingMessage =
              "You don't have enough balance to be able to proceed! ";
            console.log("No balance! please buy BUSD or something else");
            setTimeout(function () {
              window.location.reload();
            }, 5000);
          }
        } catch (e) {
          alert("Amount error");
          console.log("amount error", e);
          app.isWorking = false;
          app.workingMessage = "";
          app.activeStep = 0;
        }
      }
    },

    async approveSwap() {
      const app = this;
      if (app.web3Store.isDebug) {
        console.log("init function approve swap");
      }
      // const contract = new app.web3Store.web3.eth.Contract(
      //   app.web3Store.abiBusd,
      //   app.web3Store.contractBusd
      // );
      const contract = new app.web3Store.web3.eth.Contract(
        app.web3Store.abiBusd,
        app.selectedContract
      );
      try {
        app.isWorking = true;
        app.workingMessage =
          "Confirm on your wallet the transaction to proceed!";
        let fixedAmount = app.web3Store.web3.utils.toWei(app.amount.toString());
        const approveSwap = await contract.methods
          .approve(app.web3Store.contractPresale, fixedAmount)
          .send({ from: app.web3Store.account })
          .on("transactionHash", (tx) => {
            console.log("pendingTx", tx);
            app.pendingTx = tx;
            app.workingMessage = "Transaction in progress, please wait...";
          });
        setTimeout(function () {
          app.activeStep = 2;
          app.isWorking = false;
          app.workingMessage = "";
          app.buyZpy();
        }, 1500);
        if (app.web3Store.isDebug) {
          console.log("Try approve swap", approveSwap);
        }
      } catch (e) {
        console.log("methods.approve", e);
        app.isWorking = false;
        app.workingMessage = "";
        app.activeStep = 0;
      }
    },

    async buyZpy() {
      const app = this;
      if (app.web3Store.isDebug) {
        console.log("init function BUY $ZPY");
      }
      let fixedAmount = app.web3Store.web3.utils.toWei(app.amount.toString());

      const contract = new app.web3Store.web3.eth.Contract(
        app.web3Store.abiPresale,
        app.web3Store.contractPresale
      );
      try {
        if (app.web3Store.isDebug) {
          console.log(
            "Finally buy:",
            fixedAmount,
            "using",
            app.selectedContract
          );
        }
        const receipt = await contract.methods
          .buyZpy(app.selectedContract, fixedAmount, app.$route.params.id)
          .send({ from: app.web3Store.account })
          .on("transactionHash", (tx) => {
            console.log("pendingTx", tx);
            app.activeStep = 3;
            app.pendingTx = tx;
          });
        if (app.web3Store.isDebug) {
          console.log("Try buy swap", receipt);
        }
        app.receipt = receipt.blockHash;
        app.activeStep = 4;
      } catch (e) {
        console.log("methods.buyZpy", e);
        app.isWorking = false;
        app.workingMessage = "";
        app.activeStep = 0;
      }
    },

    reload() {
      window.location.reload();
    },
  },
};
</script>
