<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <div
        class="column is-12-mobile is-4-tablet is-4-desktop"
        :class="{ 'px-6': !isXS }"
      >
        <div>
          <div class="mb-5 has-text-centered">
            <img width="100" src="../assets/img/burn.svg" alt="" />
          </div>
          <div class="has-text-centered">
            <h4 class="mb-5">BUYBACK & BURN</h4>
            <p class="is-medium is-transparent">
              Each month, Zappy will reinvest ONE-THIRD of its revenues to buy
              $ZPYs and destroy them (the so-called BUYBACK & BURN system). This
              will cause the token to always go up in value due to the
              continuous infusion of cash and the simultaneous decrease in the
              number of tokens in circulation.
            </p>
          </div>
        </div>
        <div v-if="isXS || isSM" class="gap"></div>
      </div>
      <div
        class="column is-12-mobile is-4-tablet is-4-desktop"
        :class="{ 'px-6': !isXS }"
      >
        <div class="has-text-centered">
          <div class="mb-5">
            <img width="100" src="../assets/img/swap.svg" alt="" />
          </div>
          <div>
            <h4 class="mb-5">SWAP WITH COINZ</h4>
            <p class="is-medium is-transparent">
              COINZ is the internal currency of Zappy. Using COINZ, users will
              be able to purchase boosts and energy refills, accessories for
              their avatars, advertisements for their personal shop, themes,
              donations, and more. COINZ obtained by swapping $ZPYs will be
              priced much lower than those purchased in-app. This will give the
              user a strong incentive to purchase $ZPY to take advantage of all
              the features of the app.
            </p>
          </div>
          <div v-if="isXS || isSM" class="gap"></div>
        </div>
      </div>

      <div
        class="column is-12-mobile is-4-tablet is-4-desktop"
        :class="{ 'px-6': !isXS }"
      >
        <div class="has-text-centered">
          <div class="mb-5 has-text-centered">
            <img width="100" src="../assets/img/stake.svg" alt="" />
          </div>
          <div>
            <h4 class="mb-5">STAKING & FARMING</h4>
            <p class="is-medium is-transparent">
              With the Zappy Wallet, users will be able to stake their $ZPY or
              become liquidity providers through yield farming. In either case,
              they will receive a reward based on the months of lock and
              liquidity put into the token. This will cause staked tokens to be
              temporarily removed from circulation and liquidity to be
              continuously injected into the pool.
            </p>
          </div>
        </div>
        <div v-if="isXS || isSM" class="gap"></div>
      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "why-zpy",
  mixins: [checkViewport],
};
</script>
