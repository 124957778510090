import { defineStore } from "pinia";
////////////////////////////////////////////////////////////
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";

const abiBusd = require("@/abi/busd.json");
const abiWrapped = require("@/abi/wrapped.json");
const abiPresale = require("@/abi/presale.json");
////////////////////////////////////////////////////////////
export const useWeb3Store = defineStore("web3", {
  state: () => ({
    // CONTRACTS AND ABI
    abiBusd: abiBusd,
    abiWrapped: abiWrapped,
    abiPresale: abiPresale,

    // MAINNET CONTRACT
    contractPresale: "0xA0D32B18aA71e45483f7bd95fc163788409Ed2e9",
    contractBusd: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    contractWrapped: "0x20aee1eE67e1235FE7a3f97842f1114F986691B6",
    contractUsdt: "0x55d398326f99059fF775485246999027B3197955",
    contractUsdc: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    network: 56,

    // TESTNET CONTRACTS
    // contractPresale: "0xffc5F2ff6253976e7f192bF7D42a5D8F75B4d5Ef",
    // contractBusd: "0xbe5A50AfdF4A64C88Cac519b3Cc81bf0039F4427",
    // contractWrapped: "0xBe1511f811D5A6E26D19CD570acD3Ffb9899c2Ab",
    // network: 97,

    explorer: "https://bscscan.com/tx/",
    web3: null,
    connected: false,
    account: "",
    networks: [],
    feeData: "",
    infuraId: "0ec999a2155945ebbe096a95cdbadeea",
    balance: 0,
    balanceBusd: 0,
    balanceUsdt: 0,
    balanceUsdc: 0,
    balancePresale: 0,
    minToBuy: 0,
    bonusAmount: 0,
    amountInvested: [],
    amountEarned: [],
    amountEarnedLeader: [],
    amountInvestedLeader: [],
    isLeader: false,
    communities: {},
    endDate: "",
    startDate: "",

    // LOADERS
    isLoadingDate: false,
    isLoadingState: false,
    isLoadingUser: false,

    // DEBUG
    isDebug: false,
  }),
  actions: {
    async connect() {
      const app = this;
      if (app.isDebug) {
        console.log("Init connection");
      }
      app.isLoadingUser = true;
      let providerOptions = {};
      if (app.infuraId !== undefined) {
        providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: app.infuraId,
              rpc: {
                56: "https://bsc-dataseed.binance.org/",
              },
            },
          },
        };
      }
      // Instantiating Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });

      try {
        const provider = await web3Modal.connect({ force: true });
        app.web3 = await new Web3(provider);
      } catch (e) {
        console.log("PROVIDER_ERROR", e.message);
      }

      const netId = await app.web3.eth.net.getId();
      if (app.isDebug) {
        console.log("Current network is:", netId);
      }

      if (parseInt(netId) === parseInt(app.network)) {
        try {
          const accounts = await app.web3.eth.getAccounts();
          try {
            window.ethereum.on("accountsChanged", function (accounts) {
              if (app.isDebug) {
                console.log("account changed ====>", accounts[0]);
              }
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            });
          } catch (e) {
            console.log("Account changing ERROR", e);
          }

          // detect Network account change
          try {
            window.ethereum.on("chainChanged", function (networkId) {
              if (app.isDebug) {
                console.log("chainChanged", networkId);
              }
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            });
          } catch (e) {
            console.log("network change ERROR", e);
          }

          if (accounts.length > 0) {
            app.account = accounts[0];
            localStorage.setItem("connected", true);
            app.connected = true;
            app.isLoadingUser = false;
            //fetch info from Smart Contract
            app.fetchUserInfo();
          }
        } catch (e) {
          if (app.isDebug) {
            console.log("USER_CONNECT", e);
          }
          localStorage.setItem("connected", false);
          app.connected = false;
          app.isLoadingUser = false;
        }
      } else {
        localStorage.setItem("connected", false);
        app.connected = false;
        app.isLoadingUser = true;
        app.switchNetwork();
      }
    },

    async switchNetwork() {
      const app = this;
      app.isLoadingUser = true;
      if (app.isDebug) {
        console.log("try switch network");
      }
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: "0x" + Number(app.network).toString(16),
            },
          ],
        });
        setTimeout(function () {
          app.connect();
        }, 100);
      } catch (e) {
        // ADD BNB TESTNET IF NOT FOUND
        if (
          e.message ===
          'Unrecognized chain ID "0x61". Try adding the chain using wallet_addEthereumChain first.'
        ) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x" + Number(app.network).toString(16),
                  blockExplorerUrls: ["https://bscscan.com/"],
                  chainName: "BNB Smart Chain Mainnet",
                  nativeCurrency: {
                    decimals: 18,
                    name: "BNB Smart Chain Mainnet",
                    symbol: "BNB",
                  },
                  rpcUrls: ["https://bsc-dataseed1.binance.org/"],
                },
              ],
            });
            setTimeout(function () {
              app.connect();
            }, 100);
          } catch (e) {
            alert("Can't add BSC Testnet, please do it manually.");
            app.isLoadingUser = false;
            // console.log(
            //   "swhitch error 1 loading state:",
            //   (app.isLoadingUser = false)
            // );
          }
          // ADD BNB MAINNET IF NOT FOUND
        } else if (
          e.message ===
          'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first.'
        ) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x" + Number(app.network).toString(16),
                  blockExplorerUrls: ["https://bscscan.com"],
                  chainName: "BNB Smart Chain",
                  nativeCurrency: {
                    decimals: 18,
                    name: "BNB",
                    symbol: "BNB",
                  },
                  rpcUrls: ["https://bsc-dataseed.binance.org/"],
                },
              ],
            });
            setTimeout(function () {
              app.connect();
            }, 100);
          } catch (e) {
            alert(
              "Can't add Binance Smart Chain network, please do it manually."
            );
            app.isLoadingUser = false;
            // console.log("swhitch error 1 loading state:", app.isLoadingState);
          }
        } else {
          alert("Can't switch network, please do it manually.");
          app.isLoadingUser = false;
          // console.log("switch error 2 loading state:", app.isLoadingState);
        }
      }
      window.location.reload();
    },

    // Checking BUSD balance |||| Testnet balance |||| Fetch USD Inveted by USER
    async fetchUserInfo() {
      const app = this;
      app.isLoadingState = true;
      // Checking BUSD balance
      try {
        const contractBUSD = new app.web3.eth.Contract(
          app.abiBusd,
          app.contractBusd
        );
        const contractUSDT = new app.web3.eth.Contract(
          app.abiBusd,
          app.contractUsdt
        );
        const contractUSDC = new app.web3.eth.Contract(
          app.abiBusd,
          app.contractUsdc
        );

        // BUSD BALANCE
        try {
          app.balanceBusd = await contractBUSD.methods
            .balanceOf(app.account)
            .call();
        } catch (e) {
          console.log("cant' fetch balance BUSD", e);
        }

        // USDT BALANCE
        try {
          app.balanceUsdt = await contractUSDT.methods
            .balanceOf(app.account)
            .call();
        } catch (e) {
          console.log("cant' fetch balance USDT", e);
        }

        // USDC BALANCE
        try {
          app.balanceUsdc = await contractUSDC.methods
            .balanceOf(app.account)
            .call();
        } catch (e) {
          console.log("cant' fetch balance USDC", e);
        }

        if (app.isDebug) {
          console.log("balance BUSD", app.balanceBusd);
          console.log("balance USDT", app.balanceUsdt);
          console.log("balance USDC", app.balanceUsdc);
        }

        // Testnet balance
        // if (parseInt(app.network) === 97 && parseInt(app.balanceBusd) === 0) {
        //   const receipt = await contract.methods
        //     .mint(app.account, "1000000000000000")
        //     .send({ from: app.account });
        //   if (app.isDebug) {
        //     console.log("prova mint usd ", receipt);
        //   }
        // }
      } catch (e) {
        console.log("cant calculate balance BUSD", e);
      }

      // Fetch USD Inveted by USER
      try {
        const presaleContract = new app.web3.eth.Contract(
          app.abiPresale,
          app.contractPresale
        );
        const investedUSD = await presaleContract.methods
          .totalUsdSpentByUser(app.account)
          .call();
        app.bonusAmount = parseInt(investedUSD) / 10 ** 18;
        if (app.isDebug) {
          console.log("This bonus amount is", app.bonusAmount);
        }
        // Only for correct frontend visualization on "bonus bar"
        let multiplier;
        if (app.bonusAmount < 250) {
          multiplier = 0;
        } else if (app.bonusAmount <= 700) {
          multiplier = 5.5;
        } else if (app.bonusAmount <= 1500) {
          multiplier = 8.5;
        } else if (app.bonusAmount <= 1000) {
          multiplier = 9;
        } else if (app.bonusAmount <= 2000) {
          multiplier = 6;
        } else if (app.bonusAmount <= 3000) {
          multiplier = 4;
        } else if (app.bonusAmount <= 4000) {
          multiplier = 3.25;
        } else if (app.bonusAmount <= 5500) {
          multiplier = 2.85;
        } else if (app.bonusAmount <= 6000) {
          multiplier = 2.65;
        } else if (app.bonusAmount <= 8000) {
          multiplier = 2.15;
        } else if (app.bonusAmount <= 10000) {
          multiplier = 1.925;
        } else if (app.bonusAmount > 10000) {
          app.bonusAmount = 22000;
        }
        if (app.bonusAmount < 10001) {
          app.bonusAmount =
            Math.floor(app.bonusAmount / 100) * 100 * multiplier +
            100 * (multiplier + 5);
          if (app.isDebug) {
            console.log("this is new amount", app.bonusAmount);
          }
        }
      } catch (e) {
        console.log("I can't fetch INVESTED USD by USER", e);
      }

      //TODO: attention to loading.
      // app.isLoadingState = false;
    },

    // Fetch DATE
    async fetchDateSale() {
      const app = this;
      try {
        app.isLoadingDate = true;
        const RPC = "https://bsc-dataseed.binance.org/";
        const provider = new ethers.providers.JsonRpcProvider(RPC);
        const presaleContract = new ethers.Contract(
          app.contractPresale,
          app.abiPresale,
          provider
        );

        let callStart = await presaleContract.startTime();
        let callEnd = await presaleContract.endTime();

        callEnd = parseInt(callEnd._hex, 16);
        app.endDate = callEnd;

        callStart = parseInt(callStart._hex, 16);
        app.startDate = callStart;

        let minToBuy = await presaleContract.minZpyToBuy();
        minToBuy = parseInt(minToBuy._hex, 16);
        app.minToBuy = minToBuy;

        if (app.isDebug) {
          console.log("START date is", app.startDate);
          console.log("END date is", app.endDate);
          console.log("MIN ZPY TO BUY", minToBuy);
        }

        if (app.startDate > new Date().getTime() / 1000) {
          app.countdownDate = app.startDate;
          app.isLoadingDate = false;
        } else {
          app.countdownDate = app.endDate;
          app.isLoadingDate = false;
        }
        app.isLoadingDate = false;
        // other code
      } catch (e) {
        // console.log("I can't fetch Dates Sale", e);
        app.isLoadingDate = false;
      }
    },
  },
});
